// Angular Core
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// RxJS
import { delay, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

// Models
import {ClientSearchResponseModel, IRequestGananciaPerdida} from '../../models/client-search/client-search.model';
import { ClientBalanceModel } from '../../models/client-summary/client-summary.model';
import { AccionInversionModel } from '../../models/client-summary/accion-inversion.model';
import {IProductsCount, IRespuestaConfiguracion, totalBalanceData} from '../../../../app/features/models';
import { Products } from '../../models/enums/products.enum';

// Components
import { DialogMessageComponent, OptionsMessage } from '../../../../app/shared/components/dialog-message/dialog-message.component';

// Services
import { ClientSearchService, PreviousRouteService, DialogService, LoadingService, GtmService, LocalStorageService, DestroySubscriptionService, ContentfulService, ExecutiveService, AppService } from '../../../../app/shared/services';
import { ClientService, ClientRequestService, ClientTransactionService, ClientPopUpService, ClientPortfolioRequestService, Client } from '../../../../app/features/services';
import { environment } from '@environment';
import { CoreStatusService } from '../../../../app/core/services/coreStatus.services';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import * as moment from 'moment';
import { AliasInputLogType, InputLog } from '../../models/log/InputLog';
import { ConfigIndexLogStash } from '../../models/log/models';
import { Status } from '../../models/log/status';
import { ClientOptimusService } from '../../services/client-optimus/client-optimus.service';
import {TransversalService} from "../../services/transversal/transversal.service";

// Helper
import * as utils from '../../../shared/helpers/utils';

@Component({
    selector: 'app-client-summary',
    templateUrl: './client-summary.component.html',
    styleUrls: ['./client-summary.component.scss'],
})
export class ClientSummaryComponent implements OnInit, OnDestroy {
    clientData: ClientSearchResponseModel;
    showNoHealth$: Observable<boolean>;
    clientBalance: ClientBalanceModel[];
    rut: string = null;
        dataClient$: Observable<any>;
    products: any = null;
    visibled: boolean = false;
    isProductPershing: boolean;
    productData: any;
    productsDataEvent: IProductsCount;
    existProductPershing: any;
    configLinks: any;
    bloqueoClienteOptimus: boolean = false;
    showAsset: boolean = false;
    
    // alert compliance <app-user-message>
    showMessageCompliance = false;
    messageCompliance: string;

    showMessage = false;
    message: string;

    habilitarAsset = true;

    private readonly unsubscribe$ = new Subject<void>();
    public dataToReport: { rut: string; name: string };

    clientProducts: any;
    public showDirectAccessToOrderEntry = {
        default: false,
        insurances: false,
        pershing: false,
    };

    totalBalance: number | null = null;
    apvAmount: number | null = null;
    noApvAmount: number | null = null;
    buttonList: any;
    gettingPension: boolean = false;
    pension: any;
    showContent: boolean = false;
    showLatestTransactions: boolean = false;
    showRedirecValorCuota: boolean = false;
    clientePaf:any;
    ruteroPaf:boolean = false;
    alert: any;

    profitabilityData: IRespuestaConfiguracion;
    mostrarCard = false;
    clientInfo;
    habilitarWFI = false;
    habilitarInversiones = false;

    constructor(
        private clientRequestService: ClientRequestService,
        private clientService: ClientService,
        private loadingService: LoadingService,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private previousRouteService: PreviousRouteService,
        private clientSearchService: ClientSearchService,
        private gtmService: GtmService,
        private localStorageService: LocalStorageService,
        private destroySubscriptionService: DestroySubscriptionService,
        private contentfulService: ContentfulService,
        private router: Router,
        private appService: AppService,
        public coreStatusService: CoreStatusService,
        private tracesService: TracesService,
        private clientTransaction: ClientTransactionService,
        private clientOptimus: ClientOptimusService,
        private clientPopUpService: ClientPopUpService,
        private transversalService: TransversalService,
        private clientPortFolio : ClientPortfolioRequestService,
        private executive: ExecutiveService,
    ) {}

    async ngOnInit() {
        this.habilitarWFI = environment.WFI?.ACTIVO;
        this.loadingService.showLoading();
        this.habilitarAssetAllo();
        this.showContent = true;
        this.getFlagShowRedirectValorCuota();
        this.configLinks = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.LINKS_ORDER_ENTRY);
        const buttonEnabled = this.configLinks.links.list;
        const buttonActive = buttonEnabled.find((link: any) => link.enabled);
        if (buttonActive) this.visibled = true;

        this.showNoHealth$ = this.clientRequestService.showNoHealth;
        this.dataClient$ = this.clientService.getDataClient();
        this.dataClient$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            this.products = data.products;
        });
        this.showLatestTransactions = false;
        this.clientTransaction.showLatestTransactions.next(false);
        this.activatedRoute.params
            .pipe(
                delay(0),
                filter((params) => params?.rut !== this.rut),
                tap(() => this.loadingService.showLoading()),
                switchMap((param) => {
                    const data = this.clientRequestService.clientData.value;
                    if (data) {
                        return of(data);
                    }
                    return fromPromise(this.clientSearchService.clientSearch(param.rut)).pipe(
                        map((m) => {
                            return m.dataClient;
                        }),
                    );
                }),
                takeUntil(this.unsubscribe$),
            )
            .subscribe(async (dataClient) => {
                this.bloqueoClienteOptimus = await this.validarBloqueoOptimus();
                if (dataClient.RutCliente.length < 1) {
                    return;
                }
                this.habilitarInversiones = this.executive.getPerfil()?.executiveProfile?.allowTransaction;
                sessionStorage.setItem("tipoPer", Number(dataClient.RutCliente) > 50000000 ? 'J' : 'N');
                this.clientInfo = dataClient;
                sessionStorage.setItem("dataClient", JSON.stringify(dataClient));
                this.rut = `${dataClient.RutCliente}-${dataClient.DigitoVerificador}`;
                this.dataToReport = { rut: dataClient.RutCliente, name: dataClient.NombreCliente };
                const previous = this.previousRouteService.getPreviousUrl();
                this.ruteroPaf = await this.getPafRuteroVisibility(this.rut);

                if (!previous?.includes('investment-report')) {
                    await this.clientRequestService.loadData({
                        rut: this.rut,
                    });
                    this.clientRequestService.clientData.next(dataClient);
                }
                this.clientData = dataClient;
                this.loadingService.hideLoading();
                if(this.clientData.CodigoSegmento){
                    await this.getRentability();
                }
                await this.validateClientAFG();
                this.clientRequestService.clientAccionInversion.pipe(distinctUntilChanged(), take(1), takeUntil(this.unsubscribe$)).subscribe((accionInversion) => {
                    this.validateComplianceRules(accionInversion);
                });
            });

        this.getTransitBalances();
        this.getBalance();
        this.gtmService.pushTag('pagina-virtual', '/buscador-clientes/resumen', 'Buscador Clientes - Resumen', true);

        this.configMessageFromContentful();
        this.clientRequestService.pensionData.subscribe((pension) => {
            if (pension && pension['agencia-pago']) {
                pension['es-renta-vitalicia'] = pension['modalidad-pension'].toUpperCase().includes('RENTA VITALICIA');
                this.pension = pension;
            } else this.pension = null;
        });

        this.alert = await this.clientPopUpService.getPopups();
    }

    async validarClienteCarterizado(rut:string){
        const ownCustomers = sessionStorage.getItem('ownCustomers');
        const executiveModel = this.executive.executiveModel;
        const isSupervisor = executiveModel.executiveProfile.isSupervisor;
        const isBoss = executiveModel.executiveProfile.isBoss;
        if(ownCustomers === "true" && (isSupervisor || isBoss)){
            const clienteCartera:any = await this.clientPortFolio.getExecutivePortfolio();
            const perteneceCartera = clienteCartera.data.some( cliente => cliente.RutCliente?.toUpperCase() === rut.toUpperCase());
            if(!perteneceCartera){
                this.appService.alertaMensaje("Entendido", "Importante",`El cliente que estás buscando no es parte de tu cartera.`);
                this.router.navigate(['main/client-portfolio']);
                return;
            }
        }
    }

    showAssetClick(val : boolean){
        this.showAsset = val;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    private getTransitBalances() {
        this.clientService
            .getTransitBalance()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
                this.clientBalance = data;
            });
    }
    
    async configMessageFromContentful() {
        let dataContentful = this.localStorageService.get('dataContentful', true);

        this.showMessage = dataContentful.executiveMessage?.showMessage;
        this.message = dataContentful.executiveMessage?.message;
    }

    validateComplianceRules(accionInversion: any) {
        const compl = Array.isArray(accionInversion.compliance) ? accionInversion.compliance[0] : accionInversion.compliance;
        if (compl?.marcaCompliance === true) {
            const rut = parseInt(this.rut.split('-')[0], 10);
            const compliance:string = compl?.brands?.marcas?.marca.descripcion ?? '';
            this.messageCompliance = this.typeOfMessageCompliance(compliance, rut);
            this.showMessageCompliance = true;
            this.openModal(this.messageCompliance);
        }
    }

    openModal(messageCompliance: string) {
        const optionsMessage: OptionsMessage = {
            textButton: 'Entendido',
            buttonStyle: 'outline-grey',
            showButton: true,
            sizeButton: 'lg',
            icon: 'assets/img/icono-escudo-info.svg',
            showIcon: true,
            titleMessage: 'Importante.',
            message: messageCompliance,
        };
        const config = {
            panelClass: 'modal-md',
            disableClose: true,
            data: {
                optionsMessage,
            },
        };
        this.dialogService.openDialog(DialogMessageComponent, config);
    }

    typeOfMessageCompliance(typeCompliance: string, rut: number): string {
        let messageCompliance;
        switch (typeCompliance.toLowerCase()) {
            case 'sancionado':
                messageCompliance = 'Este cliente se encuentra &nbsp <b>sancionado por el área de compliance</b>';
                break;
            case 'pep':
                messageCompliance = 'Este cliente se encuentra categorizado como &nbsp <b>PEP (persona expuesta políticamente)</b>';
                break;
            case 'cra':
                if (rut >= 50000000) {
                    messageCompliance = `<b>Cliente CRA</b> &nbsp(Personas jurídicas que superan 500 millones de inversion para un producto o
            cúmulo de ellos en el ultimo año móvil)`;
                    break;
                }
                messageCompliance = `<b>Cliente CRA</b> &nbsp(Personas naturales que superan 6000 UF de inversion o ahorra para un producto o el cúmulo de ellos
          en el ultimo año móvil)`;
                break;
            default:
                messageCompliance = 'Cliente debe ser revisado por el área de Cumplimiento';
                break;
        }
        return messageCompliance;
    }

    getProductsData(data: IProductsCount) {
        this.productsDataEvent = data;
    }

    getBalance() {
        combineLatest([this.clientRequestService.clientBalances, this.clientRequestService.signMandate])
            .pipe(takeUntil(this.destroySubscriptionService))
            .subscribe(([data, signMandate]) => {
                if (data?.length) {
                    const erroresCore = data.filter((item) => {
                        return item.description === 'No hemos podido cargar la información';
                    });
                    this.coreStatusService.setCoreError(erroresCore);
                    const findCoreStatus = this.coreStatusService.setStatusCoreTotals().includes(false);
                    let list = [...data];
                    if (!signMandate.mandatoSuscrito) {
                        list = data.filter((item) => item.type !== 'AFP');
                    }
                    this.noApvAmount = !findCoreStatus
                        ? list
                              .filter((item) => item.prevision === Products.NO_APV && (!item.estado || item.estado?.toUpperCase() === "VIGENTE"))
                              .map((noApvItem) => noApvItem.amount ?? 0)
                              .reduce((a, acum) => a + acum, 0)
                        : null;

                    this.apvAmount = !findCoreStatus
                        ? list
                              .filter((item) => item.prevision === Products.APV && (!item.estado || item.estado?.toUpperCase() === "VIGENTE"))
                              .map((noApvItem) => noApvItem.amount ?? 0)
                              .reduce((a, acum) => a + acum, 0)
                        : null;
                    
                    this.showDirectAccessToOrderEntry.default = this.noApvAmount !== null || this.apvAmount !== null ? true : false;
                    this.showDirectAccessToOrderEntry.insurances = data.find((element) => element.type === 'INSURANCES') ? true : false;

                    this.totalBalance = !findCoreStatus ? this.apvAmount + this.noApvAmount : null;
                    const clienteBalances = JSON.parse(sessionStorage.getItem("clienteBalances"));
                    if(clienteBalances?.length) return;
                    const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
                        Categoria: 'Resumen Cliente',
                        Operacion: 'Resumen Cliente',
                        Metodo: 'getBalance()',
                        eventoLog: 'Saldos',
                        status: Status.OK,
                        Secuencia: 0,
                        EntradaLog: {
                            accion: 'Saldos cargados',
                            saldos: {
                                montoFinanciero: this.noApvAmount,
                                montoPrevisional: this.apvAmount,
                                totalBalance: this.totalBalance,
                                detalle: data
                            },
                        },
                    };
                    this.logData(logtemp, 'log_negocio');
                    sessionStorage.setItem("clienteBalances", JSON.stringify(data))
                }else{
                    this.noApvAmount = 0;
                    this.apvAmount = 0;
                    this.totalBalance = 0;
                }
                
                const balances: totalBalanceData = {
                    montoFinanciero: this.noApvAmount,
                    montoPrevisional: this.apvAmount,
                    totalBalance: this.totalBalance,
                };
                sessionStorage.setItem('totalBalance', btoa(JSON.stringify(balances)));
            });
    }

    async reloadBalance() {
        this.loadingService.showLoading();
        await this.clientRequestService.loadData({ rut: this.rut });
        this.loadingService.hideLoading();
    }

    async validateClientAFG(): Promise<boolean> {
        let rut: string;
        this.activatedRoute.params.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
            rut = params.rut;
        });
        const isClientAGF = await this.clientSearchService.validateClientAGF(rut, undefined);
        this.loadingService.hideLoading();
        return isClientAGF;
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Secuencia = 0;
            inputLog.Tipo_sesion = 'ASESOR';

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }

    loadLatestTransactions() {
        this.clientTransaction.showLatestTransactions.next(true);
        this.showLatestTransactions = true;
    }

    async getFlagShowRedirectValorCuota() {
        const entryID = environment.KEYS.CONTENTFUL.CONFIG_VALOR_CUOTA_SHOW_ACCESS;
        const dataContent = await this.contentfulService.getEntryFromFront(entryID);
        this.showRedirecValorCuota =
            dataContent.environmentList.environments.filter((item: string) => {
                return item === environment.name;
            }).length > 0;
    }

    async goSiteClientViewFounds() {
        const clientData = this.clientRequestService.clientData.value;
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goSiteClientViewFounds()',
            eventoLog: 'click boton ver comportamiento',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton ver comportamiento redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.clientService.goToSiteClient(
            {
                rut: `${clientData.RutCliente}-${clientData.DigitoVerificador}`,
                nombre: clientData.NombreCliente,
                apellidoPaterno: clientData.NombreCliente.split(' ')[0],
                apellidoMaterno: clientData.NombreCliente.split(' ')[1],
            },
            'comportamiento-fondos',
        );
    }

    async goSiteClientViewInstallmentValue() {
        const clientData = this.clientRequestService.clientData.value;
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goSiteClientViewInstallmentValue()',
            eventoLog: 'click boton ver valores cuota',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton ver valores cuota redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.clientService.goToSiteClient(
            {
                rut: `${clientData.RutCliente}-${clientData.DigitoVerificador}`,
                nombre: clientData.NombreCliente,
                apellidoPaterno: clientData.NombreCliente.split(' ')[0],
                apellidoMaterno: clientData.NombreCliente.split(' ')[1],
            },
            'valores-cuotas',
        );
    }

    async validarBloqueoOptimus(){
        try {
            const response = await this.clientOptimus.getValidarBloqueoOptimus();
            return response.bloqueo;
        } catch (error) {
            return false;
        }
      
    }

    

    async habilitarAssetAllo(){
        const {enabled, environmentList} = await 
          this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.HABILITAR_ASSET_ALLOCATION);
          const existeAmbiente = environmentList.includes(environment.name);
          this.habilitarAsset = enabled && existeAmbiente;

          return this.habilitarAsset;
    }

    async getPafRuteroVisibility(rut: string) {
        try {
            const ruteroID = environment.KEYS.CMSRUTERO.PAF;
            const [dataRutero] = await this.clientRequestService.getRutero(ruteroID);
            const included: boolean = dataRutero ? dataRutero?.active && dataRutero?.ruteroArr?.includes(rut) : true;
            return included;
        } catch (error) {
            console.log(error);
            return false;
        }

    }

    private async getRentability() {
        const mandato = await utils.customLastValueFrom(this.clientRequestService.signMandate.pipe(takeUntil(this.unsubscribe$), filter(value => Object.keys(value).length > 0)))
        const requestGananciaPerdida: IRequestGananciaPerdida = {
            rut: this.rut,
            segmento: utils.getDescripcionSegmentoPorId(this.clientData.CodigoSegmento),
            mandatoFirmado: mandato.mandatoSuscrito ?? false
        }
        this.profitabilityData = await this.transversalService.getGananciaPerdida(requestGananciaPerdida)
        this.mostrarCard = true;
    }

    irWFI(){
        this.loadingService.showLoading();
        const path = '/main/client-search/summary/' + this.rut + '/landing-investments';
        this.router.navigate([path]);
        setTimeout(() => {
            this.loadingService.hideLoading();
        },2000);
    }
}
